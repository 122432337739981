/**
 * Created by chathura_f on 9/19/2016.
 */

app.controller('CheckOutController', function (titleChange, $scope, jitoPaths, $window, jitoUser, $location, jitoStorage, jitoShared, jitoCommon, $translate, jitoEvents, jitoAjax,
                                               jitoSettings, jitoHybrid, jitoCamera, jitoValidations, $routeParams, $timeout, $anchorScroll) {

    "ngInject";
    $scope.statuses = jitoSettings.RELATIONSHIP_STATUS;
    $scope.subTotal = 0;
    $scope.GST = jitoSettings.GST;
    $scope.orderID = $.trim($routeParams.orderID);
    $scope.orderData = null;
    $scope.eventId;
    $scope.eventSlug;
    $scope.orderDetails = {};
    $scope.subDetailCount = 0 ;
    $scope.isTableOnly = ($location.search().tb && $location.search().tb == 'true') ? true : false;
    $scope.sessions = [];
    $scope.sessionSelectionError = "";
    $scope.years = [{key: '', value: 'Please select'}];
    $scope.months = [];
    $scope.cardDetails = {
        cc_number: '',
        cc_cvc: '',
        cc_exp_month: '',
        cc_exp_year: '',
        token: ''
    };
    $scope.isProcessingOrder = false;
    $scope.countryNamesForBilling = [];
    $scope.paymentMethodSimplify = jitoSettings.PAYMENT_METHOD_SIMPLIFY;
    $scope.paymentMethodSquare = jitoSettings.PAYMENT_METHOD_SQUARE;
    $scope.paymentMethod = jitoSettings.DEFAULT_PAYMENT_METHOD;
    $scope.isSquareLoading = true;

    var squarePayment, squareCard; 
    var isSquareInitialized = false;

    var getYearsAndMonths = function () {
        var now = new Date();

        for(var i = 0; i < 10; i++) {
            var x = now.getFullYear() + i;
            $scope.years.push({
                key: x.toString().substr(-2),
                value: x
            });
        }

        $scope.months = [
            {key: '', value: 'Please select'},
            {key: '01', value: 'Jan'},
            {key: '02', value: 'Feb'},
            {key: '03', value: 'Mar'},
            {key: '04', value: 'Apr'},
            {key: '05', value: 'May'},
            {key: '06', value: 'Jun'},
            {key: '07', value: 'Jul'},
            {key: '08', value: 'Aug'},
            {key: '09', value: 'Sep'},
            {key: '10', value: 'Oct'},
            {key: '11', value: 'Nov'},
            {key: '12', value: 'Dec'}
        ];
    };

    getYearsAndMonths();

    $scope.personalData = {
        'first_name': '',
        'last_name': '',
        'job_title': '',
        'company': '',
        'email': '',
        'address_line01': '',
        'address_line02': '',
        'state_id': '',
        'country_id': '',
        'postal_code': '',
        'dietary_requirements': '',
        'billing_address_1': '',
        'billing_address_2': '',
        'billing_address_city': '',
        'billing_address_country':'',
        'billing_address_state':'',
        'billing_address_post_code':''
    };

    //Title Change
    titleChange.setTitle('Checkout');

    //Update GST
    $scope.updateGST = function () {
        //if country is Australia this should be called
        if ($scope.personalData.billing_address_country == jitoSettings.GST_INCLUDED_COUNTRIES.AUSTRALIA) {
            //get the past  total value, gst and update
            var tax = $scope.subTotal * $scope.GST;
            var total = tax + $scope.subTotal;

            //if coupon is included
            if ($scope.orderData.discount_price > 0) {
                tax = ($scope.subTotal - $scope.orderData.discount_price) * $scope.GST;
                total = ($scope.subTotal - $scope.orderData.discount_price) + tax;
            }

            var data = {
                tax: tax,
                total: total
            };

            //gst should be updated
            jitoAjax.call({
                method: 'PUT',
                url: jitoSettings.getAPIDomain() + jitoSettings.updateTaxTotalPriceForOrder() + $scope.orderID,
                data: data,
                timeout: 60000 //jitoSettings.LOGIN_REQUEST_TIMEOUT
            }, function (response) {
                if (response && response.data && response.status == 200) {
                    $scope.orderData.tax = tax;
                    $scope.orderData.total = total;
                } else {
                    orderUpdateError(response);
                }
            }, listError);
        }
        else {
            $scope.orderData.tax = 0;
            //if coupon is included
            if ($scope.orderData.discount_price > 0) {
                $scope.orderData.total = $scope.subTotal - $scope.orderData.discount_price;
            }
            else {
                $scope.orderData.total = $scope.orderData.tax + $scope.subTotal;
            }
            var data1 = {
                tax: $scope.orderData.tax,
                total: $scope.orderData.total
            };
            //gst should be updated
            jitoAjax.call({
                method: 'PUT',
                url: jitoSettings.getAPIDomain() + jitoSettings.updateTaxTotalPriceForOrder() + $scope.orderID,
                data: data1,
                timeout: 60000 //jitoSettings.LOGIN_REQUEST_TIMEOUT
            }, function (response) {
                if (response && response.data && response.status == 200) {
                    console.log(response);
                } else {
                    orderUpdateError(response);
                }
            }, listError);
        }

    }

    /**
     * Return array object
     */
    var getNumber = function(num) {
        var arry=[];
        for (var i = 0 ; i < num ; i++) {
            arry.push({
                'first_name': '',
                'last_name': '',
                'job_title': '',
                'company': '',
                'email': '',  
                "is_general": 0,
                "attend_no" : i+2,
                "dietary_requirements": '',
                "selected_sessions": []
            });
        }
        $scope.subDetailList = arry;  
    }


    /**
     * Order details success
     * @param response
     */
    var listSuccess = function (response) {
        var errorMessage = '';
        $scope.isLoading = false;
        if (response && response.status && response.status === 200) {
            if (response.data.data) {
                $scope.orderData = response.data.data;
                $scope.subDetail = [];
                $scope.orderDetails = $scope.orderData.order_details;
                $scope.eventId = response.data.data.event_id;
                $scope.eventSlug = response.data.data.event_slug;
                $scope.sessions = $scope.orderData.session_details.event_sessions.sessions;
                $scope.countryListForBilling = response.data.countryListForBilling.data;

                angular.forEach($scope.orderDetails , function(value, key) {
                  if(value.ticket_type == 1){
                     $scope.subDetailCount += value.amount;
                  }
                });
             
                 //Set form detail array
                getNumber($scope.subDetailCount-1);

                if (response.data.data.order_user_details.length > 0) {

                    var userObject = response.data.data.order_user_details[0];

                    if (userObject.type == 1) {
                        //This is a user
                        $scope.personalData.first_name = userObject.user_meta.first_name;
                        $scope.personalData.last_name = userObject.user_meta.last_name;
                        $scope.personalData.job_title = userObject.user_meta.current_job;
                        $scope.personalData.company = userObject.user_meta.company;
                        $scope.personalData.email = userObject.email;
                        $scope.personalData.dietary_requirements = '';

                    } else {
                        //This is a brand
                        $scope.personalData.first_name = '';
                        $scope.personalData.last_name = '';
                        $scope.personalData.job_title = '';
                        $scope.personalData.company = userObject.brand_meta.brand_name;
                        $scope.personalData.email = userObject.email;
                        $scope.personalData.dietary_requirements = '';
                    }

                } else {
                    //No user object available.
                }

                /**
                 * Calculate sub total
                 */
                angular.forEach($scope.orderData.order_details, function(order){
                    $scope.subTotal  =  $scope.subTotal + order.row_total;
                });

                if($scope.orderData.child_order) {
                    angular.forEach($scope.orderData.child_order.order_details, function(childOrder){
                        $scope.subTotal  =  $scope.subTotal + childOrder.row_total;
                    });
                }

            }
            else {
                response($translate.instant('COMMON_NO_NETWORK'));
            }
        } else {
            response($translate.instant('COMMON_NO_NETWORK'));
        }
    };

    /**
     * Order details error
     * @param response
     */
    var listError = function (response) {
        var errorMessage = '';
        $scope.isLoading = false;
        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        }
        else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }
        $scope.listErrorMessage = errorMessage;
    };

    var loadInterestData = function () {
        $scope.isLoading = true;
        jitoAjax.call({
            method: 'get',
            url: jitoSettings.getAPIDomain() + jitoSettings.getEventOrderAPI() + $scope.orderID,
            timeout: jitoSettings.DEFAULT_TIME_OUT
        }, listSuccess, listError);
    };

    /**
     * Load order details
     * @param response
     */
    loadInterestData();


    /**
     * order update error
     * @param response
     */
    var orderUpdateError = function (response) {

        var errorMessage = '';
        $scope.isRequesting = false;
        $scope.isProcessingOrder = false;

        if (response.status > 200 && response.status < 500 && response.data) {
            errorMessage = jitoCommon.normalizeErrorMessages(response.data);
        } else {
            errorMessage = $translate.instant('COMMON_NO_NETWORK');
        }

        $scope.errorMessage = errorMessage;
        //$location.hash('main');
        //$anchorScroll();

        $location.path('checkout-error/' + errorMessage);
    };

    $scope.getCountryName = function (id) {
        var countryName =  $scope.countryListForBilling.reduce(function (p, c) {
            return (c.id == id && p.push(c.name), p)
        }, [])[0];

        return countryName;
    }

    /**
     * order update
     * @param response
     */
    $scope.updateOrderInfo = function (isValid) {

        if (isValid) {

            var tempData = [], sessionSelection = [], sessionErrorCount = 0;
            $scope.sessionSelectionError = "";
            $scope.isProcessingOrder = true;

            angular.forEach($scope.sessions, function (value, key) {
                var singleSession = {
                    session_id: value.id,
                    event_id: $scope.orderData.session_details.event_rooms.rooms.event_id,
                    rooms_id: $scope.orderData.session_details.event_rooms.rooms.id
                };

                if (value.is_default == 0 && value.is_selected == null) {
                    sessionErrorCount += 1;
                    singleSession.session = null;
                } else {
                    if (value.is_default == 1) {
                        singleSession.session = 1;
                    } else {
                        singleSession.session = value.is_selected == 2 ? 2 : 1;
                    }
                }

                sessionSelection.push(singleSession);
            });


            if (sessionErrorCount > 0 && !$scope.isTableOnly) {
                $scope.sessionSelectionError = $translate.instant(
                  'TICKET_SESSION_SELECTION_ERROR');

                
                $scope.errorMessage = $scope.sessionSelectionError;
                $scope.isRequesting = false;
                $scope.isProcessingOrder = false;

                return false;
            }

            var genDetail = {
                "first_name": $scope.personalData.first_name,
                "last_name": $scope.personalData.last_name,
                "job_title": $scope.personalData.job_title,
                "company": $scope.personalData.company,
                "email": $scope.personalData.email,
                "is_general": 1,
                "attend_no": 1,
                "dietary_requirements": $scope.personalData.dietary_requirements,
                "selected_sessions": sessionSelection,
                "billing_address_1": $scope.personalData.billing_address_1,
                "billing_address_2": $scope.personalData.billing_address_2,
                "billing_address_city": $scope.personalData.billing_address_city,
                "billing_address_country": $scope.getCountryName(
                  $scope.personalData.billing_address_country),
                "billing_address_state": $scope.personalData.billing_address_state,
                "billing_address_post_code": $scope.personalData.billing_address_post_code
            };

            tempData.push(genDetail);
            angular.forEach($scope.subDetailList, function (value, key) {
                tempData.push(value);
            });

            var data = {
                "attendees": tempData,
                "address_line01": "",
                "address_line02": "",
                "state_id": "",
                "country_id": "",
                "postal_code": ""
            };

            $scope.isRequesting = true;

            var paymentDetails = {
                idempotencyKey: crypto.randomUUID(),
                amount: ($scope.orderData.total).toString(),
                billingContact: {},
                currencyCode: 'AUD',
                intent: 'CHARGE',
            };

            processSquarePayment(paymentDetails, data);
        }
    };

    /**
     * Checkout cancel function
     */
    $scope.cancel = function () {

        alertify.confirm($translate.instant('WARNING_CANCEL_CHECKOUT'),
            function () {
                $scope.isRequesting = true;
                jitoAjax.call({
                    method: 'PUT',
                    url: jitoSettings.getAPIDomain() + jitoSettings.getOrderCancelAPI() + $scope.orderID,
                    timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
                }, function (response) {
                    if (response && response.data && response.status == 200) {
                        jitoShared.setOrderCancelStatus(true);
                        $location.path('/event/' + $scope.eventSlug).search({});
                    } else {
                        orderUpdateError(response);
                    }
                }, orderUpdateError);
            },
            function () {
                jitoShared.setOrderCancelStatus(false);
            });
    };

    $scope.$on('onOrderCancel', function (event, next) {
        alertify.confirm($translate.instant('WARNING_CANCEL_CHECKOUT'),
            function () {
                jitoAjax.call({
                    method: 'PUT',
                    url: jitoSettings.getAPIDomain() + jitoSettings.getOrderCancelAPI() + $scope.orderID,
                    timeout: jitoSettings.LOGIN_REQUEST_TIMEOUT
                }, function (response) {
                    if (response && response.data && response.status == 200) {
                        jitoShared.setOrderCancelStatus(true);
                        //console.log(next);
                        $location.path(next);
                    } else {
                        orderUpdateError(response);
                    }
                }, orderUpdateError);
            },
            function () {
                jitoShared.setOrderCancelStatus(false);
            });
    });

    $scope.updateSessionSelection = function(session_id, session) {
        var sessionErrorCount = 0;

        angular.forEach($scope.sessions, function(value, key) {
            if(value.id == session_id) {
                value.is_selected = session;
            }

            if(value.is_default == 0 && value.is_selected == null) {
                sessionErrorCount += 1;
            }
        });

        $timeout(function () {
            $scope.sessionSelectionError = (sessionErrorCount > 0) ? $translate.instant('TICKET_SESSION_SELECTION_ERROR') : "";
        }, 100);
    };

    var createSecure3dEmvForm = function(data) {
        return data.redirectHtml;
    }

    $scope.$watch('paymentMethod', function(newVal) {
        if (newVal === jitoSettings.PAYMENT_METHOD_SQUARE && !isSquareInitialized) {
            initializeSquarePaymentForm();
        }
    });

    // Initialize Square payment form
    async function initializeSquarePaymentForm() {
        const appId = jitoSettings.SQUARE_APPLICATION_ID;
        const locationId = jitoSettings.SQUARE_LOCATION_ID;

        squarePayment = Square.payments(appId, locationId);

        const cardOption = {
            style: {
                input: {
                    backgroundColor: 'white'
                }
            }
        };

        try {
            squareCard = await squarePayment.card(cardOption);
            await squareCard.attach('#card-container');
        } catch (e) {
            $scope.errorMessage = 'Invalid card details. Please try again.';
            $scope.isSquareLoading = false;
            $scope.$apply(); 
        } finally {
            isSquareInitialized = true; 
            $scope.isSquareLoading = false;
            $scope.$apply(); 
        }
    }

    async function processSquarePayment(paymentDetails, orderDetails) {
        try {
            const tokenResult = await squareCard.tokenize();

            const verificationResults = await squarePayment.verifyBuyer(
                tokenResult.token,
                paymentDetails
            );

            paymentDetails.amount = (parseInt(paymentDetails.amount * 100)).toString();
            // paymentDetails.amount = (parseInt(paymentDetails.amount) * 100).toString();
            paymentDetails.token = tokenResult.token;
            paymentDetails.verificationToken = verificationResults.token;

            orderDetails.token = tokenResult.token;
            orderDetails.paymentMethod = $scope.paymentMethod;
            orderDetails.paymentDetails = paymentDetails;

            jitoAjax.call({
                method: 'PUT',
                url: jitoSettings.getAPIDomain() + jitoSettings.putEventOrderUpdateAPI() + $scope.orderID,
                data: orderDetails,
                timeout: 60000
            }, function (response) {
                if (response && response.data && response.status == 200) {
                    $scope.isRequesting = true;
                    $scope.isProcessingOrder = true;
                    $location.path('checkout-success').search({ booking_id: response.data.data.id });
                } else {
                    orderUpdateError(response);
                    $location.path('checkout-error/' + jitoCommon.normalizeErrorMessages(response.data));
                }
            }, orderUpdateError);
        } catch (error) {
            $scope.errorMessage = 'Invalid card details. Please try again.';
            $scope.isRequesting = false;
            $scope.isProcessingOrder = false;

            try {
              $scope.$apply();
            } catch (e) {
                
            }
        }
    }

    $scope.currentYear = new Date().getFullYear();
});
